import styled from 'styled-components'
import React from 'react'
import HomeHeroSection from '@loggedOut/HomeHeroSection'
import { CTAWave, StorySlider, TestimonialSection, TextCoverRowNew, Collapsible} from '@common'
import HeroMenu  from '@menus/HeroMenu'
import { useTranslation } from 'react-i18next'
import Seo from "@components/Seo";


const FAQWrapper = styled.div`
  max-width:1000px;
  margin: 50px auto 100px auto;
  display:flex;
  flex-direction:column;
  justify-content:center;
  @media ${props => props.theme.tabletBelow}{
    max-width:90%;
  }
  @media ${props => props.theme.desktopUp}{
    margin: 50px auto 200px auto;
  }
`

const SeoLandingpage = (props) => {
  const { t } = useTranslation()
  const {
    heroTitle, heroText, firstTitle, firstText,
    firstStory, FAQContent, SEOTitle, SEODescription,
    CTAText, CTATextSecond, firstSlider, stories, sliderContent
  } = props

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": FAQContent.map(pair => (
      {
        "@type": "Question",
        "name": pair.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": pair.answer
        }
      }
    )),
  }
  

  return (
    <>
      <Seo title={SEOTitle}
        description={SEODescription}
        structuredData={[faqSchema]}
      />
      <HeroMenu/>
      <HomeHeroSection headerTitle={heroTitle} headerText={heroText} ctaText={(t('seo-landingpage.cta-1'))} showText/>
      <StorySlider 
        title={firstSlider.title} 
        subtitle ={firstSlider.subtitle} 
        contentIds={sliderContent[0]}
        stories={stories}
        order={firstSlider.order} 
      />
      <CTAWave title ={CTAText} buttonText={(t('seo-landingpage.cta-2'))}/>
      <TextCoverRowNew
        title={firstTitle}
        text={firstText}
        story={stories[firstStory]}

      />
      <TestimonialSection/>
      <FAQWrapper>
        {FAQContent.map(edge =>(
          <Collapsible
            key={edge.question} 
            question={edge.question}
            answer={edge.answer}
          />
        ))}
      </FAQWrapper>
      <StorySlider
        title= {t('seo-landingpage.sliderTitle-1')}
        subtitle ={t('seo-landingpage.sliderSubtitle-1')}
        contentIds={sliderContent[1]}
        stories={stories}
      />
      <StorySlider
        contentIds={sliderContent[2]}
        stories={stories}
        title= {t('seo-landingpage.sliderTitle-2')}
        subtitle ={t('seo-landingpage.sliderSubtitle-2')}
      />
      <StorySlider
        contentIds={sliderContent[3]}
        stories={stories}
        title= {t('seo-landingpage.sliderTitle-3')}
        subtitle ={t('seo-landingpage.sliderSubtitle-3')}
      />
      <StorySlider
        contentIds={sliderContent[4]}
        stories={stories}
        title= {t('seo-landingpage.sliderTitle-4')}
        subtitle ={t('seo-landingpage.sliderSubtitle-4')}
      />
      <StorySlider
        contentIds={sliderContent[5]}
        stories={stories}
        title= {t('seo-landingpage.sliderTitle-5')}
        subtitle ={t('seo-landingpage.sliderSubtitle-5')}
      /> 
      <CTAWave title ={CTATextSecond} buttonText={t('seo-landingpage.cta-3')}/>
    </>
  );
}

export default SeoLandingpage