import React, {useContext}  from 'react'
import SeoLandingpage from '@loggedOut/SeoLandingpage'
import {useFilterStoriesByFantasy} from '@hooks/useFilterStoriesByFantasy'
import {FirebaseContext} from '@components/Firebase'
import Seo from '@components/Seo'
import FantasyPage from '@common/loggedIn/FantasyPage'
import {graphql} from 'gatsby';
import Header from "@menus/header"
import {useObjectifyStoryArray} from '@hooks/useObjectifyStoryArray'


const SeoLandingpageTemplate = (props) => {
  const storiesObject = useObjectifyStoryArray(props.data.allStory)
  const { heroTitle, heroText, firstTitle, firstText, firstStory, secondTitle, secondText, faqContent, seoTitle, seoDescription, firstCtaText, secondCtaText, firstSlider, videoUrl
    } = props.pageContext.landingpage[props.pageContext.lang]
  const fantasy = props.pageContext.fantasy
  const {user} = useContext(FirebaseContext);
  const customQuery  = useFilterStoriesByFantasy(props.data.allStory.edges, props.pageContext.fantasySlug, props.pageContext.lang)
   
  return (
    <>
      <Header location={props.location}/> 
      {!!user ?
        <>
          <Seo description={seoDescription} title={seoTitle}/>
          <FantasyPage stories={customQuery} fantasy={fantasy}/>
        </>
        :
        <SeoLandingpage
          heroTitle={heroTitle}
          heroText={heroText}
          firstTitle={firstTitle}
          firstText={firstText}
          firstStory={firstStory}
          secondTitle={secondTitle}
          secondText={secondText}
          videoUrl={videoUrl}
          FAQContent={faqContent}
          SEOTitle={seoTitle}
          SEODescription={seoDescription}
          CTAText={firstCtaText}
          CTATextSecond={secondCtaText}
          firstSlider={firstSlider}
          stories={storiesObject} 
          sliderContent={props.pageContext.sliderContent}
        />
      }
    </>
  );
}

// this way will inject query into props.data
export const query = graphql`
  query LandingPageFantasyQuery($isEn: Boolean!, $isEs: Boolean!, $isDe: Boolean!) {
    allStory {
      edges {
        node {
          ...allStoryInfo 
        }
      }
    }
    dummyToUseVarInFragment: allStory(filter: {id: {eq: "nevertrue"}}){
      edges {
        node{
          id @include(if: $isEs)
          id @include(if: $isEn)
          id @include(if: $isDe)
        }
      }
    }
  }
`;  
 
export default SeoLandingpageTemplate